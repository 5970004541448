// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-telemundo-center-index-js": () => import("./../../../src/pages/about-telemundo-center/index.js" /* webpackChunkName: "component---src-pages-about-telemundo-center-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-props-index-js": () => import("./../../../src/pages/props/index.js" /* webpackChunkName: "component---src-pages-props-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../../../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */),
  "component---src-pages-wardrobe-index-js": () => import("./../../../src/pages/wardrobe/index.js" /* webpackChunkName: "component---src-pages-wardrobe-index-js" */)
}

